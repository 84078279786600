<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <detail-sanitaire
      :animal="animal"
      :create-by="createBy"
      :create-at="createAt"
    >
      <template slot="header">
        <h4>{{ vaccin.libelle }} [{{ vaccin.valence }}]</h4>
      </template>
      <template slot="content">
        <div class="row info">
          <div class="col-6">
            <h6>Maladie</h6>
            <p>{{ maladie }}</p>
          </div>
          <div class="col-6">
            <h6>Vaccin</h6>
            <p>{{ vaccin.libelle }} ({{ vaccin.valence }})</p>
          </div>
        </div>
        <div class="row info">
          <div class="col-6">
            <h6>Date de vaccination</h6>
            <p>{{ vaccination.dateVaccination|showTime }}</p>
          </div>
          <div class="col-6">
            <h6>Date de rappel</h6>
            <p>{{ vaccination.dateRappel|showTime }}</p>
          </div>
        </div>
        <div class="row info">
          <div class="col-12">
            <h6>Information supplémentaire</h6>
            <p>{{ vaccination.info }}</p>
          </div>
        </div>
      </template>
    </detail-sanitaire>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import navbar from '../../../components/navbar.vue';
import DetailSanitaire from '../../../components/sanitaire/detailSanitaire.vue';
export default {
    components: {navbar, DetailSanitaire},
    data(){
        return {
            navbarItems: [
              {
                  libelle: 'Sanitaire'
              },
              {
                libelle: 'Vaccinations',
                route: 'vaccination'
              },
              {libelle: 'Detail'}
            ],
            pageIcon: 'la-id-card',
            pageTitle: 'Détail vaccination',
            pageDescription: 'Détail de la vaccination sur un animal',
            vaccination: null
        }
    },
    watch: {
        vaccinations: {
            handler(){
                this.load()
            },
            deep: true
        }
    },
    mounted(){
        this.load()
    },  
    methods: {
        load(){
            if(this.$route.params.uid !== null){
                this.vaccination =  this.vaccinations.find(item => item.uid === this.$route.params.uid)
            }else{
                this.vaccination =  null
            }
            
        }
    },
    computed: {
        ...mapGetters({
            vaccins: 'sanitaire/vaccins',
            vaccinations: 'sanitaire/vaccinations',
            maladies: 'sanitaire/maladies'
        }),
        hasVaccination() { return this.vaccination !== null && this.vaccination !== undefined},
        animal(){
            if(this.hasVaccination) return this.vaccination.animal
            return null
        },
        createBy(){
            return (this.hasVaccination)? this.vaccination.createBy:null
        },
        createAt(){
            return (this.hasVaccination)? this.vaccination.createdAt:null
        },
        vaccin(){
            if(this.hasVaccination) {
                let v = this.vaccins.find(item => item.uid === this.vaccination.vaccin)
                if(v) return v
            }
            
            return null
        },
        maladie(){
            if(this.hasVaccination) {
                let m = this.maladies.find(item => item.uid === this.vaccination.maladie)
                if(m) return m.libelle
            }
            return '-'
        }
    }

}
</script>

<style>

</style>